/* eslint-disable no-unused-vars */
import $ from 'jquery';
import angular from 'angular';
import 'angular-sanitize';
import 'angular-animate';
import 'angular-cookies';
import 'angular-route';
import 'angular-i18n/angular-locale_fr';
import uiCollapse from 'angular-ui-bootstrap/src/collapse';
import uiDropdown from 'angular-ui-bootstrap/src/dropdown';
import uiButtons from 'angular-ui-bootstrap/src/buttons';
import uiTabs from 'import/js/libs/angular-ui-bootstrap/src/tabs';
import uiTooltip from 'angular-ui-bootstrap/src/tooltip';
import uiPagination from 'angular-ui-bootstrap/src/pagination';
import uiPopover from 'angular-ui-bootstrap/src/popover';
import 'ngSmoothScroll';
import 'Build/routes.js';
import 'js/routing.js';
import 'js/routing-listeners.js';
import 'less/master.less';
import 'import/js/libs/angular-toastr/angular-toastr.js';
import 'import/js/libs/angular-toastr/toast.tpl';
import 'import/js/libs/angular-toastr/toast_message.tpl';
import 'import/js/libs/angular-toastr/toast_progressbar.tpl';
import 'import/js/libs/angular-toastr/toast_addtocart.tpl';
import 'import/js/libs/bootstrap-angular-validation/bootstrap-angular-validation.js';
import 'js/controllers/controller.modal.js';
import 'js/controllers/controller.main.js';
let rootJsControllers = ['controller.modal', 'controller.main'];
import 'js/directives/directive.bxslider.js';
import 'js/directives/directive.countdown.js';
import 'js/directives/directive.photoslurp.js';
import 'js/directives/directives.form.js';
import 'js/directives/directive.breadcrumb.js';
import 'js/directives/directive.clearuibtab.js';
import 'js/directives/directive.fb-connect.js';
import 'js/directives/directive.infinitescroll.js';
import 'js/directives/directive.owl-carousel.js';
import 'js/directives/directive.page.head.js';
import 'js/directives/directive.scrolltop.js';
import 'js/directives/directive.selectpicker.js';
import 'js/directives/directive.stop.propagation.js';
import 'js/directives/directive.togglePasswordVisibility.js';
import 'js/directives/directive.visibleonscroll.js';
let rootJsDirectives = ['directive.bxslider', 'directive.countdown', 'directive.photoslurp', 'directives.form', 'directive.breadcrumb', 'directive.clearuibtab', 'directive.fb-connect', 'directive.infinitescroll', 'directive.owl-carousel', 'directive.page.head', 'directive.scrolltop', 'directive.selectpicker', 'directive.stop.propagation', 'directive.togglePasswordVisibility', 'directive.visibleonscroll'];
import 'js/filters/filter.decimal.js';
import 'js/filters/filter.discount.js';
import 'js/filters/filter.format.js';
import 'js/filters/filter.phone.js';
import 'js/filters/filter.price.js';
import 'js/filters/filter.truncate.date.js';
let rootJsFilters = ['filter.decimal', 'filter.discount', 'filter.format', 'filter.phone', 'filter.price', 'filter.truncate.date'];
import 'js/modules/module.modal.js';
import 'js/modules/module.products.grid.js';
import 'js/modules/module.cdn.js';
import 'js/modules/module.gtm.js';
import 'js/modules/module.lazy.js';
import 'js/modules/module.netwave.js';
import 'js/modules/module.spinner.js';
let rootJsModules = ['module.modal', 'module.products.grid', 'module.cdn', 'module.gtm', 'module.lazy', 'module.netwave', 'module.spinner'];
import 'js/services/service.cp.js';
import 'js/services/service.account.js';
import 'js/services/service.app.js';
import 'js/services/service.authentication.js';
import 'js/services/service.availability.js';
import 'js/services/service.country.js';
import 'js/services/service.date.js';
import 'js/services/service.gmap.js';
import 'js/services/service.http.js';
import 'js/services/service.price.js';
import 'js/services/service.responsive.js';
import 'js/services/service.string.js';
import 'js/services/service.window-events.js';
import 'js/services/service.youtube.js';
let rootJsServices = ['service.cp', 'service.account', 'service.app', 'service.authentication', 'service.availability', 'service.country', 'service.date', 'service.gmap', 'service.http', 'service.price', 'service.responsive', 'service.string', 'service.window-events', 'service.youtube'];
import 'less/components/animation.less';
import 'less/components/basscss.less';
import 'less/components/carousel.less';
import 'less/components/cms.less';
import 'less/components/inputs.less';
import 'less/components/reviews.less';
import 'less/components/tabs.less';
import 'less/components/toastr.less';
import 'less/components/button-add-to-cart-classic.less';
let rootLessComponents = ['animation', 'basscss', 'carousel', 'cms', 'inputs', 'reviews', 'tabs', 'toastr', 'button-add-to-cart-classic'];
import 'Modules/Account/js/account-check.directive.js';
import 'Modules/Authentication/js/directive.minilogin.js';
import 'Modules/Cart/js/directive.be2bill-embedded.js';
import 'Modules/Category/js/directive.filters.js';
import 'Modules/Payment/js/directive.quotation-payment.js';
import 'Modules/Product/js/component.customization.extra.js';
import 'Modules/Product/js/directive.carrousel.zoom.js';
import 'Modules/Product/js/directive.delivery.tomorrow.js';
import 'Modules/Product/js/directive.kit.js';
import 'Modules/Product/js/directive.max.length.js';
import 'Modules/Product/js/directive.product.elementaries.js';
import 'Modules/Product/js/module.product.js';
import 'Modules/Reviews/js/module.reviews.js';
import 'Modules/Shared/Footer/js/controller.newsletter.js';
import 'Modules/Shared/Header/js/directive.header.js';
import 'Modules/Shared/Menu/js/directive.nav.js';
import 'Modules/Account/js/check.identity.service.js';
import 'Modules/Account/js/directive.check.identity.js';
import 'Modules/Authentication/js/controllers.authentication.js';
import 'Modules/Cart/js/directive.mini-cart.js';
import 'Modules/Category/js/directive.truncate.js';
import 'Modules/Category/js/module.category.js';
import 'Modules/Form/js/jobs.form.js';
import 'Modules/Form/js/section.form.js';
import 'Modules/PasswordRecovery/js/password.recovery.js';
import 'Modules/Payment/js/confirmation.js';
import 'Modules/Payment/js/directive.mty.tpe.js';
import 'Modules/Product/js/component.customization.js';
import 'Modules/Product/js/directive.price.js';
import 'Modules/Product/js/directive.product.gallery.js';
import 'Modules/Product/js/directive.share.js';
import 'Modules/Search/js/search.js';
import 'Modules/Shared/Autocomplete/js/directive.autocomplete.product.js';
import 'Modules/Shared/Payment/Alma/js/directive.alma.widget.js';
import 'Modules/Stores/js/controller.modal.store.choice.js';
import 'Modules/Stores/js/directive.infos.store.js';
import 'Modules/Stores/js/directive.store.js';
import 'Modules/Stores/js/directive.store.map.js';
import 'Modules/Stores/js/directive.stores.js';
import 'Modules/Wishlist/js/directive.wishlist.js';
let jsModules = ['account-check.directive', 'directive.minilogin', 'directive.be2bill-embedded', 'directive.filters', 'directive.quotation-payment', 'component.customization.extra', 'directive.carrousel.zoom', 'directive.delivery.tomorrow', 'directive.kit', 'directive.max.length', 'directive.product.elementaries', 'module.product', 'module.reviews', 'controller.newsletter', 'directive.header', 'directive.nav', 'check.identity.service', 'directive.check.identity', 'controllers.authentication', 'directive.mini-cart', 'directive.truncate', 'module.category', 'jobs.form', 'section.form', 'password.recovery', 'confirmation', 'directive.mty.tpe', 'component.customization', 'directive.price', 'directive.product.gallery', 'directive.share', 'search', 'directive.autocomplete.product', 'directive.alma.widget', 'controller.modal.store.choice', 'directive.infos.store', 'directive.store', 'directive.store.map', 'directive.stores', 'directive.wishlist'];
import 'Modules/Cart/less/cart.less';
import 'Modules/Form/less/form.less';
import 'Modules/Home/less/home.less';
import 'Modules/Shared/Footer/less/footer.less';
import 'Modules/Shared/Header/less/header.less';
import 'Modules/Shared/Menu/less/nav.less';
import 'Modules/Category/less/category.less';
import 'Modules/Faq/less/faq.less';
import 'Modules/Finder/less/finder.less';
import 'Modules/Reviews/less/reviews.less';
import 'Modules/Shared/less/shared.less';
import 'Modules/Static/less/SiteMap.less';
let lessModules = ['cart', 'form', 'home', 'footer', 'header', 'nav', 'category', 'faq', 'finder', 'reviews', 'shared', 'SiteMap'];
window.$ = $;
export default angular.module('dependencies', ['ngSanitize', 'ngAnimate', 'ngCookies', 'ngRoute', uiCollapse, uiDropdown, uiButtons, uiTabs, uiTooltip, uiPagination, uiPopover, 'smoothScroll', 'app.routes', 'app.routing', 'app.routing-listeners', 'toastr', 'bootstrap.angular.validation', ...rootJsControllers, ...rootJsDirectives, ...rootJsFilters, ...rootJsModules, ...rootJsServices, ...jsModules]);